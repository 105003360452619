import React from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./components/home";
import Section from "./components/parts/Section";
import About from "./components/parts/About/About";
import Projects from "./components/parts/Projects/Projects";
import Pricing from "./components/parts/Pricing/Pricing";
import Contact from "./components/parts/Contact/Contact";
import CookieBanner from 'react-cookie-banner';
import tree from './components/parts/img/tree.svg'


function App() {
  return (
    <div className="App">
      <Header/>
      <Home/>
      <Section id='about' reverse={false} inner={<About></About>}/>
      <Section id='projects' reverse={false} inner={<Projects></Projects>}></Section>
      {/* <Section id='pricing' reverse={true} inner={<Pricing></Pricing>}></Section> */}
      <Section id='contact' inner={<Contact></Contact>}></Section>
      <Footer/>
    </div>
  );
}

export default App;
