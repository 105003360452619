import React from 'react'

function Disclaimer() {
    return(
        <div className='info-box'>
            <div className='title'>
                <h2 className='title-h'>
                    Disclaimer
                </h2>
            </div>
            <div className='content'>
                <h4 className='content-h'>Illustraties gemaakt door: <a href="https://storyset.com/online">Storyset</a></h4>
            </div>
        </div>
    );
}

export default Disclaimer;