import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import "./ServicesHome.scss";
import { BsArrowDown } from 'react-icons/bs';
import bg from './img/servicesHome3.svg';

function ServicesHome() {
    return (
        <div className="servicehome" id="home">
            <div className="servicehome-title">
                <h1>Ontdek hier al onze Services!</h1>
                <div className="bounceArrow">&darr;</div>
            </div>
            <div className="servicehome-img">
                {/* <img src={bg}></img> */}
            </div>
        </div>
    );
}

export default ServicesHome;