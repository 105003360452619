import React from 'react';
import './Section.scss';


function Section(props) {
    var styleSection = {};
    if(props.reverse === true) {
        styleSection={background: '#004b50' , color: '#FFFFFF'};
    }

    return (
        <section style={styleSection} className='section tag' id={props.id}>
            {props.inner}
        </section>
    );
}

export default Section;