import React from 'react'
import { List } from 'semantic-ui-react'

function TermsOfUse() {
    const products = [
        {key:1, name:"Design", description:"Het design van een website is de eerste stap tot de ontwikkeling. Een goed uitgewerkt design wordt in samenspraak met de klant opgemaakt. Het design wordt gemaakt voor de overeengekomen webpagina's zoals vermeld in de offerte. "},
        {key:2, name:"Ontwikkeling website: ", description:"De uitwerking van de website is de tweede stap. Het ontwerp wordt omgezet naar een operationele website online. Het product wordt afgeleverd binnen de afgesproken termijn. Dit product is een eenmalige kost, berekend op basis van de complexiteit van de website."},
    ]
    const services = [
        {   
            key:1,
            name:"Basis Service ", 
            description:"Deze Basis Service bevat het onderhoud van de website, het beheer van de domeinnaam en het onderhoud van de server. "
            + "De klant kan steeds vragen om een tekst, foto, kleur, of andere kleinigheid aan te passen op de website of om een kleine esthetische aanpassing door te voeren op de website zoals het aanpassen van een basiskleur. "
            + "Grotere, meer complexe aanpassingen vallen niet binnen dit product. Onder dit product vallen dus bijvoorbeeld NIET: het bijvoegen van een extra pagina, het volledig aanpassen van de layout van de website, een volledige makeover. "
            + "Verder zit in deze service ook het beheer van één domeinnaam naar keuze van de klant en de kost voor het opslaan van bestanden op de webserver. "
            + "Dit product is een maandelijkse kost en wordt ook maandelijks of jaarlijks (naar believen van de klant) aan het begin van de aanslagmaand/-jaar gefactureerd. "
            + "Indien de klant de hosting wenst te beëindigen, kan dit op eender welk moment. De factuur van elke bogonnen maand moet echter wel nog betaald worden! "
            + "In dit pakket zit ook een basis implementatie van SEO. Deze implementatie wordt kwartaals geüpdatet om de website meer zichtbaar te maken op het internet. "
            + "Bij betaling van de vastgelegde prijs voor dit Basis Service pakket, verbindt TheCodingTree er zich toe om dit Basis Service pakket gedurende de vastgelegde periode na te leven en uit te voeren voor de klant. "
            + "TheCodingTree is te allen tijde gemachtigd de prijzen van de producten en services te veranderen, mits de klanten ten minste 31 dagen voor de ingang van de prijswijziging op de hoogte te stellen. "
        },
        {
            key: 2,
            name: "SEO uitbreidingen",
            description: "Indien de klant dit wenst, kan het basis SEO pakket uitgebreid worden met extra optimalisaties. Wij voorzien bij TheCodingTree verschillende uitbreidingen die op maat zijn van de wensen van de klant. Hierbij is ook een mogelijkheid tot terugkoppeling met prestatiecijfers naar de klant toe."
        },
        {
            key: 3,
            name: "Mailboxen",
            description: "Gepersonaliseerde mailboxen geeft uw bedrijf een professionele look. Bij TheCodingTree bieden wij pakketten aan van mailboxen tot volledige Microsoft Bussiness Packs of Google Business Packs. En dat allemaal op maat van uw bedrijf. De opties worden doorgenomen met de klant. TheCodingTree zorgt ervoor dat de mailboxen operationeel zijn en dat, indien de klant dit wenst, deze te gebruiken zijn in uw mailinterface naar keuze."
        },
        {
            key: 4,
            name: "IT ondersteuning",
            description: "Bij TheCodingTree ondersteunen we bedrijven graag in hun digitale transformatie. IT-consulting is onze expertise en we staan dan ook paraat om u te helpen bij al uw computer en internetvragen en -problemen. Wij bieden ondersteuning aan een eerlijke prijs."
        }
    ]
    return(
        <div className='info-box'>
            <div className='title'>
                <h2 className='title-h'>
                    Gebruiksvoorwaarden
                </h2>
            </div>
            <div className='content'>
                <h4 className='content-h'>
                    Bij TheCodingTree vinden we transparantie belangrijk.
                    Daarom lijsten we hier graag even op waaraan u zich verbindt als klant bij ons.
                    <div className='subtitle'>Productenbeschrijving</div>
                    <ul>
                        {products.map(product =>
                            <li key={product.key}><b>{product.name}</b> {product.description}</li>
                        )}
                    </ul>
                    <div className="subtitle">Services</div>
                    <ul>
                        {services.map(service =>
                            <li key={service.key}><b>{service.name}</b> {service.description}</li>
                        )}
                    </ul>
                    <div className='subtitle'>Offertes</div>
                    Offertes zijn steeds van toepassing op de vermelde producten. Offertes dienen aanvaard te worden binnen de vermelde periode. De inhoud van de offerte is steeds op voorhand doorgesproken met de klant. Indien een offerte aanvaard wordt, zullen de producten binnen de afgesproken periode afgeleverd worden, startende vanaf de aanvaarding van de offerte.
                    <div className='subtitle'>Facturatie</div>
                    Facturen worden steeds bij aflevering van het product of voor maandelijkse facturatie bij het begin van de maand afgeleverd. De standaard periode voor betaling is 10 werkdagen, tenzij anders vermeld.
                </h4>
            </div>
        </div>
    );
}

export default TermsOfUse;