import React from 'react'
import Header from './components/header';
import Footer from './components/footer';
import Section from './components/parts/Section';
import Privacy from './components/parts/Info/Privacy'
import Cookies from './components/parts/Info/Cookies'
import TermsOfUse from './components/parts/Info/TermsOfUse'
import Disclaimer from './components/parts/Info/Disclaimer';
import './components/parts/Info/Info.scss'


function Info() {
    return (
      <div className="App">
          <Header/>
          <div className='page-title'>
            <div className='title'><h1>Gegevens</h1></div>
          </div>
          <Section id='privacy' inner={<Privacy></Privacy>}></Section>
          <Section id='cookies' inner={<Cookies></Cookies>}></Section>
          <Section id='termsofuse' inner={<TermsOfUse></TermsOfUse>}></Section>
          <Section id='disclaimer' inner={<Disclaimer></Disclaimer>}></Section>
          <Footer/>
      </div>
    );
  }
  
  export default Info;