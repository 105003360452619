import React from "react";
import FooterElementCol from "./FooterElementCol";
import SocialMediaCol from "./SocialMediaCol";
import CopyRight from "./CopyRight";

import './style.scss'

const Footer = props => {
    // const elements = [{}]
    var el = {name:'Services', list: [{elementName: 'Web Development', link:'/services#web-development'}, {elementName: 'Hosting & Onderhoud', link: '/services#hosting'}, {elementName: 'Webstore & Dashboards', link: '/services#dashboard'}]};
    var el2 = {name:'Contact', list: [{elementName: 'info@thecodingtree.be', link:'mailto:info@thecodingtree.be'}, {elementName: '+32476547586', link: 'tel:+32476547586'},{elementName: 'BE0784.471.860', link:''}]};
    var el3 = {name: 'Info', list: [{elementName: 'Privacy & gegevens', link: '/info#privacy'}, {elementName: 'Cookies', link: '/info#cookies'}, {elementName: 'Gebruiksvoorwaarden', link: '/info#termsofuse'}]}
    return (
        <footer className="footer">
            <div className="footer-grid">
                <FooterElementCol footerElement={el}></FooterElementCol>
                <FooterElementCol footerElement={el3}></FooterElementCol>
                <FooterElementCol footerElement={el2}></FooterElementCol>
                <SocialMediaCol></SocialMediaCol>                
            </div>
        </footer>
    );
}



export default Footer;