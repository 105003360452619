import React from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./components/home";
import Section from "./components/parts/Section";
import About from "./components/parts/About/About";
import Projects from "./components/parts/Projects/Projects";
import Pricing from "./components/parts/Pricing/Pricing";
import Contact from "./components/parts/Contact/Contact";
import CookieBanner from 'react-cookie-banner';
import ShiftedBox from "./components/parts/ShiftedBox";
import duoparty from './components/parts/img/duoparty.be.png';
import duodashboard from './components/parts/img/duoparty.dashboard.png';
import duodashboardhome from './components/parts/img/duoparty.dashboard.home.png';
import domain from './components/parts/img/domain.jpg'
import ServicesHome from "./components/home/ServicesHome";
import Consulting from "./components/parts/img/consulting.jpg";

const shiftedBoxes = [
    {id: 'web-development', title: 'Web Development', image: duoparty, explain: 'Bij ons vindt u de specialist in websites & web applicaties. TheCodingTree heeft expertise in PHP, Javascript, React ... en staat dus in elk geval voor u klaar! U kan er ook voor kiezen om een CMS te integreren (zoals WordPress).'},
    {id: 'hosting',title: 'Hosting & onderhoud', image: domain, explain: 'Bij TheCodingTree nemen wij het beheer van uw hosting en webdomein op ons. Wij regelen voor u alle administratieve en technische taken in verband met uw website, domeinnaam, database, webmails, security, ... Ook kleine aanpassingen aan uw website kan u 7/7 doorgeven en staan gegarandeerd binnen 24u online. Geen verdere kopzorgen meer! Contacteer ons nu!'},
    {id: 'dashboard',title: 'Webshop & Dashboard', image: duodashboard, explain: 'Bent u van plan een webwinkel te openen. Dan kan u ook daarvoor bij ons terecht. Wij maken een op maat gemaakte webwinkel voor uw zaak! Of bent u eerder op zoek naar een dashboard voor het verzamelen van data of bestellingen? TheCodingTree zit met u samen om de benodigdheden voor uw zaak uit te werken.'},
    {id: 'consulting',title: 'Consulting', image: Consulting, explain: 'Bij TheCodingTree kan u terecht voor consulting op maat. We helpen u graag uw bedrijf te automatiseren en digitaal sterk te maken. Neem contact met ons op en we bekijken samen wat we voor u kunnen betekenen.'},
]

function Services() {
  return (
    <div className="App">
        <Header/>
        <ServicesHome></ServicesHome>
        {shiftedBoxes.map((data,index)=>{
            var dir = true;
            index%2 === 0 ? dir = false : dir = true;
            return <Section reverse={dir} id={data.id} inner={<ShiftedBox
              key={index}
              id={data.id}
              title={data.title}
              direction={dir}
              explain={data.explain}
              image={data.image}
              ></ShiftedBox>}></Section>
        })}
        <Footer/>
    </div>
  );
}

export default Services;
