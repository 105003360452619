import React from 'react'

function Cookies() {
    return(
        <div className='info-box'>
            <div className='title'>
                <h2 className='title-h'>
                    Cookies
                </h2>
            </div>
            <div className='content'>
                <h4 className='content-h'>
                    Bij TheCodingTree wordt er enkel gebruik gemaakt van functionele cookies. Dit zijn essentiële gegevens om de website te laten werken, zoals bijvoorbeeld de taalinstelling van de website.
                    Er worden dus geen cookies doorgeven aan derden, alsook worden er geen analytische of commerciëel gerichte cookies verworven.
                </h4>
            </div>
        </div>
    );
}

export default Cookies;