import React from 'react';
import me from '../img/brecht_vandekerkhove.jpg';
import './About.scss';


function About(props) {
    return (
        <div className='row'>
            <div className='about-box'>
                <div className='about-text'>
                    <h3>Ik ben Brecht. <br/> Master Student Burgerlijk Ingenieur Werktuigkunde - Mechatronica & Robotica aan de KU Leuven. <br/>
                    Sinds het begin van mijn universiteits-carrière ben ik gefascineerd door het maken van websites en webapplicaties. Ik merk dat bij kleine bedrijven een hoge nood is aan digitale hulp. Daarom besloot ik zelf op pad te gaan en de kleine zelfstandige hierin te helpen.</h3>
                </div>
                <div className='about-profile'>
                    <div className='about-photo'>
                        <img src={me}></img>
                    </div>
                    <div className='about-name'>
                        <h4>Brecht Vandekerkhove</h4>
                        <h5>Student Burgerlijk Ingenieur Werktuigkunde - Robotica & Mechatronica aan KULeuven</h5>
                        <h5>Oprichter TheCodingTree</h5>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;