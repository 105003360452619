import React from 'react'
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './ContactForm.scss';

const ContactForm = () => {

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast('Bericht succesvol verzonden!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email, subject, message, company } = data;
    const my_domain = 'thecodingtree.be';
    const my_email = 'info@thecodingtree.be';
    try {
      const templateParams = {
        name,
        email,
        subject,
        message,
        company,
        my_domain,
        my_email
      };

//      EMAILJS
        emailjs.send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_USER_ID,
        );

        reset();
        toastifySuccess();
        document.getElementById('contactForm').style.display = 'none';
        document.getElementById('mailSend').style.display = 'block';

    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className='ContactForm'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 text-center'>
            <div className='contactForm' id='contactForm'>
              <form id='contact-form' onSubmit={handleSubmit(onSubmit)}>{/* onSubmit={handleSubmit(onSubmit)} noValidate*/}
                {/* Row 1 of form */}
                <div className='row formRow'>
                  <div className='col-6'>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Vergeet uw naam niet' },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      className='form-control formInput'
                      placeholder='Naam*'
                    ></input>
                    {errors.name && <span className='errorMessage'>Vergeet uw naam niet in te vullen</span>}
                  </div>
                  <div className='col-6'>
                    <input
                      type='text'
                      name='company'
                      {...register('company', {
                        required: { value: false },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      className='form-control formInput'
                      placeholder='Bedrijf'
                    ></input>
                    {/* {errors.name && <span className='errorMessage'>{errors.name.message}</span>} */}
                  </div>
                  <div className='col-6'>
                    <input
                      type='email'
                      name='email'
                      {...register('email', {
                        required: true,
                        pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })}
                      className='form-control formInput'
                      placeholder='Email adres*'
                    ></input>
                    {errors.email && (
                      <span className='errorMessage'>Vul alstublieft een geldig email adres in</span>
                    )}
                  </div>
                </div>
                {/* Row 2 of form */}
                <div className='row formRow'>
                  <div className='col'>
                    <input
                      type='text'
                      name='subject'
                      {...register('subject', {
                        required: { value: true, message: 'Please enter a subject' },
                        maxLength: {
                          value: 75,
                          message: 'Subject cannot exceed 75 characters'
                        }
                      })}
                      className='form-control formInput'
                      placeholder='Onderwerp*'
                    ></input>
                    {errors.subject && (
                      <span className='errorMessage'>Vergeet geen onderwerp in te vullen</span>
                    )}
                  </div>
                </div>
                {/* Row 3 of form */}
                <div className='row formRow'>
                  <div className='col'>
                    <textarea
                      rows={3}
                      name='message'
                      {...register('message', {
                        required: true
                      })}
                      className='form-control formInput'
                      placeholder='Bericht*'
                    ></textarea>
                    {errors.message && <span className='errorMessage'>Vergeet geen bericht in te schrijven</span>}
                  </div>
                </div>
                <button className='submit-btn' type='submit' >
                  <h4>Verstuur</h4>
                </button>
              </form>
            </div>
            <div className='mailSend' id='mailSend'>
              <h4>Uw bericht werd succesvol verzonden!</h4>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ContactForm;