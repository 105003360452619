import React from "react";
import './ShiftedBox.scss';
import duoparty from './img/duoparty.be.png';
import duodashboard from './img/duoparty.dashboard.png';
import duodashboardhome from './img/duoparty.dashboard.home.png';

function ShiftedBox(props) {
    const shift = () => {
        if(props.direction === true) {
            return(
                <div className='box'>
                    <div className='image'>
                        <img className="img" src={props.image}></img>
                    </div>
                    <div className='text'>
                        <div className='title'>
                            <h3>{props.title}</h3>
                        </div>
                        <div className='explain'>
                            <h4>{props.explain}</h4>
                        </div>
                    </div>
                </div>
            );
        } else {
            return(
                <div className='box'>
                    <div className='text'>
                        <div className='title'>
                            <h3>{props.title}</h3>
                        </div>
                        <div className='explain'>
                            <h4>{props.explain}</h4>
                        </div>
                    </div>
                    <div className='image'>
                        <img className="img" src={props.image}></img>
                    </div>
                </div>
            );
        }
    };
    
    
    return(
        <div className='row'>
            {shift()}
        </div>
    );
};

export default ShiftedBox;