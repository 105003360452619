import React from 'react';
import './Projects.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import duoparty from '../img/duoparty.be.png';
import duodashboard from '../img/duoparty.dashboard.png';
import duodashboardhome from '../img/duoparty.dashboard.home.png';
import mvmarketing from '../img/mv-marketingsolutions.png'
import mvcontact from '../img/mv-contact.png'
import verrelstHome from '../img/verrelst-tuinen.png'
import verrelstExpertise from '../img/verrelst-expertise.png'
import compact_agency from '../img/compact-agency.png'


function Projects(props) {
    return (
        <div className='row'>
            <div className='projects-box'>
                <div className='projects-slide'>
                    <OwlCarousel className='owl-theme' dots={false} items={1} margin={10} autoplay rewind autoplayTimeout={3000} autoplayHoverPause>
                        <div className="item"><img src={mvmarketing} alt="Owl Image" ></img></div>
                        <div className="item"><img src={compact_agency} alt="Owl Image" ></img></div>
                        <div className="item"><img src={duoparty} alt="Owl Image" ></img></div>
                        <div className="item"><img src={verrelstHome} alt="Owl Image" ></img></div>
                        <div className="item"><img src={mvcontact} alt="Owl Image" ></img></div>
                        <div className="item"><img src={verrelstExpertise} alt="Owl Image" ></img></div>
                        <div className="item"><img src={duodashboard} alt="Owl Image"></img></div>
                        <div className="item"><img src={duodashboardhome} alt="Owl Image"></img></div>
                    </OwlCarousel>
                </div>
                <div className='projects-text'>
                    <div className='projects-title'>
                        <h3>Bekijk onze eerdere partners</h3>
                    </div>
                    <div className='projects-explain'>
                        <h4>TheCodingTree staat in de startblokken van haar traject. Bij ons worden de producten steeds met kwaliteit afgeleverd. Hierbij staan kost, veiligheid & gebruiksgemakkelijkheid centraal.</h4>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Projects;