import React from "react";
import contact from '../img/contact_image.svg';
// import './user-research-style.css';
import ContactForm from "./ContactForm";
import './Contact.scss';


function Contact() {
  return (
    <div className="contact" id='contact'>
      <div className="row">
        <div className="contact-box">
          <div className="contact-col">
            <h3 className="mb-4">Neem contact met ons op en vraag uw gratis offerte of vrijblijvend gesprek aan!</h3>
            <p>Twijfelt u? Neem contact op en we bekijken samen wat wij voor u kunnen betekenen!</p>
            <img src={contact} alt="Image" className="contact-img"></img>
          </div>  
          <div className="contact-col">
            <ContactForm></ContactForm>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;