import React from 'react';
import './style.scss';
import tree from './img/tree.png';
import { IoChevronDown } from 'react-icons/io5';
import { HiMenu } from 'react-icons/hi'
import HamburgerMenu from "./HamburgerMenu";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from '../home'


const Header = props => {
    return (
        <header id='header' className='header'>
            <div className='logo'>
                <a href='./'>
                    <span className="logo-title"><h4>TheCoding</h4><h4 className="logo-title-tree"><img className="tree-title" src={tree}></img>ree</h4></span>
                    <span className="logo-slogan"><h6>Your website with a touch!</h6></span>
                </a>
            </div>
            <div className='contactButton'><a href='/#contact'>Contact</a></div>
            <div className='menu'>
                <HamburgerMenu className="hamburger" />
            </div>
        </header>
    );
}



export default Header;