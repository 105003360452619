import React from 'react'
import {FaFacebook, FaGithub, FaTwitter, FaInstagram, FaLinkedin} from 'react-icons/fa';
import CopyRight from './CopyRight';

function SocialMediaCol() {
  return (
    <div className="footer-element-col">
      <div className="footer-element-title"><h2>Media</h2></div>
      <div className="footer-element-list">
        <ul className="footer-icons">
            <a target="_blank" href='https://www.facebook.com/profile.php?id=100008076045290'><FaFacebook></FaFacebook></a>
            <a target="_blank" href='https://github.com/Brecht-CodeLab'><FaGithub></FaGithub></a>
            <a target="_blank" href="https://www.linkedin.com/in/brecht-vandekerkhove-3369ab1a4/"><FaLinkedin></FaLinkedin></a>
        </ul>
      </div>
      <CopyRight></CopyRight>
    </div>
    );
  }
  
  export default SocialMediaCol;