import React from 'react'
import {Link} from 'react-router-dom'

function Pricay() {
    return(
        <div className='info-box'>
            <div className='title'>
                <h2 className='title-h'>
                    Privacy
                </h2>
            </div>
            <div className='content'>
                <h4 className='content-h'>
                    Bij TheCodingTree vinden we de privacy van onze klanten van enorm belang.
                    We streven ernaar een product met een zo hoog mogelijke kwaliteit af te leveren.
                    Hierbinnen valt voor ons dus ook veiligheid omtrent klantengegevens. 
                    Om veiligheid te garanderen, zijn we continu in de weer om onze software te optimaliseren en beter te beveiligen.
                    Indien u hierover vragen heeft, mag u ons altijd contacteren via onze <a href='/#contact' className='' style={{textDecoration: 'underline'}}>contactpagina.</a>
                    <div className='subtitle'>Welke gegevens verzamelen wij?</div>
                    <ul >
                        <li>Bedrijfsnaam</li>
                        <li>Naam verantwoordelijke</li>
                        <li>Adres hoofdzetel</li>
                        <li>Email-adres</li>
                        <li>Telefoon nummer</li>
                        <li>Bedrijfslogo</li>
                        <li>BTW-nummer</li>
                    </ul>

                    <div className='subtitle'>Wat vangen we met die informatie aan?</div>
                    De bovenvermelde gegevens worden enkel gebruikt om de klantenrelatie te onderhouden en te factureren. 
                    Door klant te zijn bij TheCodingTree geeft u automatisch ook het recht aan TheCodingTree om uw logo op onze website te vertonen.
                    Verder worden er nooit ongevraagd gegevens van klanten op onze websites vrijgegeven.                  
                </h4>
            </div>
        </div>
    );
}

export default Pricay;