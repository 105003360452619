import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import App from './App';
import About from './components/parts/About/About'
import './style.scss';
import Services from './Services';
import CookieConsent from 'react-cookie-consent';
import Info from './Info'


  // Import the functions you need from the SDKs you need
  import { initializeApp } from "firebase/app";
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries
  // const sgMail = require('@sendgrid/mail')

  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyCE-1SHmTBeSILAUxlCLIIXZva8l5ZPC20",
    authDomain: "thecodingtreewebsite.firebaseapp.com",
    projectId: "thecodingtreewebsite",
    storageBucket: "thecodingtreewebsite.appspot.com",
    messagingSenderId: "694375392767",
    appId: "1:694375392767:web:4fe61da851ab61670733ca"
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <Router>
    <div className="pages">
      <Routes>
        <Route exact path="/" element={<App/>}/>
        <Route exact path="/services" element={<Services/>}/>
        <Route exact path="/info" element={<Info />}/>
      </Routes>
    </div>
    </Router>
    <CookieConsent debug={false} location="bottom"
      buttonText="Ik begrijp het!"
      cookieName="verrelst.company"
      style={{width: "calc(100% - 2rem)", height: "auto", borderRadius: "20px", marginLeft: "1rem", marginBottom: "1rem" ,background: "#2B373B" }}
      buttonStyle={{ marginRight: "1rem", borderRadius: "20px", background: "#04ad8f", color: "#FFFFFF", fontSize: "13px" }}>Deze website maakt enkel gebruik van functionele cookies.</CookieConsent>
  </React.StrictMode>,
  document.getElementById('root')
);
