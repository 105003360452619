import React from 'react'

function FooterElementCol(props) {
  const elementName = props.footerElement.name;
  const elementList = props.footerElement.list;
  var elements = [];

  function ListElement(element,key) {
    return (
      <li key={key} className="footer-element"><a href={element.link}>{element.elementName}</a></li>
    );
  }

  for(var i = 0; i<elementList.length;i++) {
    elements.push(ListElement(elementList[i],i));
  }

  return (
    <div className="footer-element-col">
      <div className="footer-element-title"><h2>{elementName}</h2></div>
      <div className="footer-element-list">
        <ul className="footer-elements">
            {elements}
        </ul>
      </div>
    </div>
    );
  }
  
  export default FooterElementCol;