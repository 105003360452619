import React from 'react'
import { FaRegCopyright } from "react-icons/fa";

function CopyRight () {
    const year = new Date().getFullYear()
  
    return (
      <div className="footer-copyright">
          <h6>This website is made by <a className="thecodingtree-link" href="/">TheCodingTree</a></h6>
          <h6>All rights reserved <FaRegCopyright></FaRegCopyright> {year}</h6>
      </div>
      );
    }
    
    export default CopyRight;