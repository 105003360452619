import React from "react";
import "react-alice-carousel/lib/alice-carousel.css";
import "./style.scss";
import wave  from './img/wave.png';

function Home() {
    return (
        <div className="home" id="home">
            <div className="home-title">
                <h1>Welcome at TheCodingTree!</h1><br/>
                <h2>Klaar voor de digitalisering van uw zaak?</h2>
            </div>
            {/* <img className="wave" src={wave} alt="" style={{bottom: '-1px', position: 'absolute'}}></img> */}
        </div>
    );
}

export default Home;